
import './App.css';

function App() {
  return (
    <>
      <div className='container'>
        <h1>Andy Silk</h1>
        <p className='location'>Auckland, New Zealand</p>
        <a className='button' href='mailto:andysilk555@gmail.com'>Get in touch</a>
      </div>
    </>
  );
}

export default App;
